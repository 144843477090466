@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Light.eot');
    src: local('Ubuntu Light'), local('Ubuntu-Light'),
    url('Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-Light.woff2') format('woff2'),
    url('Ubuntu-Light.woff') format('woff'),
    url('Ubuntu-Light.ttf') format('truetype'),
    url('Ubuntu-Light.svg#Ubuntu-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Bold.eot');
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
    url('Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-Bold.woff2') format('woff2'),
    url('Ubuntu-Bold.woff') format('woff'),
    url('Ubuntu-Bold.ttf') format('truetype'),
    url('Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-BoldItalic.eot');
    src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
    url('Ubuntu-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-BoldItalic.woff2') format('woff2'),
    url('Ubuntu-BoldItalic.woff') format('woff'),
    url('Ubuntu-BoldItalic.ttf') format('truetype'),
    url('Ubuntu-BoldItalic.svg#Ubuntu-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Italic.eot');
    src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
    url('Ubuntu-Italic.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-Italic.woff2') format('woff2'),
    url('Ubuntu-Italic.woff') format('woff'),
    url('Ubuntu-Italic.ttf') format('truetype'),
    url('Ubuntu-Italic.svg#Ubuntu-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-MediumItalic.eot');
    src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'),
    url('Ubuntu-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-MediumItalic.woff2') format('woff2'),
    url('Ubuntu-MediumItalic.woff') format('woff'),
    url('Ubuntu-MediumItalic.ttf') format('truetype'),
    url('Ubuntu-MediumItalic.svg#Ubuntu-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-LightItalic.eot');
    src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'),
    url('Ubuntu-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-LightItalic.woff2') format('woff2'),
    url('Ubuntu-LightItalic.woff') format('woff'),
    url('Ubuntu-LightItalic.ttf') format('truetype'),
    url('Ubuntu-LightItalic.svg#Ubuntu-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Medium.eot');
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
    url('Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-Medium.woff2') format('woff2'),
    url('Ubuntu-Medium.woff') format('woff'),
    url('Ubuntu-Medium.ttf') format('truetype'),
    url('Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Regular.eot');
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
    url('Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
    url('Ubuntu-Regular.woff2') format('woff2'),
    url('Ubuntu-Regular.woff') format('woff'),
    url('Ubuntu-Regular.ttf') format('truetype'),
    url('Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

