.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


p{
  font-size: 16px;
}

/*.pswp__zoom-wrap {*/
/*    position: relative !important;*/
/*    height: 100vh !important;*/
/*    left: 0!important;*/
/*top: 0px;*/
/*transform: none !important;*/
/*}*/
/*.pswp__img{*/
/*width: 300px!important;*/
/*height: auto!important;*/
/*object-fit: cover;*/
/*position: absolute;*/
/*top: 50%!important;*/
/*left: 50%!important;*/
/*transform: translate(-50%, -50%)!important;*/
/*}*/
@media (min-width: 1024px){
  .centrato{
    bottom: 260px
    /*top:50%;*/
    /*transform: translateY(-50%);*/
  }
  .verdescuro{
    background: #21442B!important;
  }

  p{
    font-size: 18px;
  }
}


.half-screen .main {
  height: 50vh;
  margin-top: 30vh;
  overflow-y: scroll;
}

