@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overflow-x-auto::-webkit-scrollbar {
  display: none;
}
.overflow-x-auto{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.z-9999{
  z-index: 99999999;
}
.z-99999{
  z-index: 999999999;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  border-radius: 20px;
  border: solid 2px;
  border-color: white;
  background: #96A42C;
}

::-webkit-scrollbar-thumb {
  background: #61681d;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  border: solid 2px;
  border-color: transparent;
}

::-webkit-scrollbar-corner {
  background: #000;
}
